.landingContainer{
    margin-top: 100px;
}

.landingHeader{
    font-size: 5em;
    font-weight: 800;
}

.landingDesc{
    font-size: 23px;
    padding:30px 0;
}
