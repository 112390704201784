.collectionContainer{
    margin-top: 20px;
    margin-left: -25px !important;
}

.cardHeader{
    font-size: 20px;
    font-weight: 700;
}

.cardDesc{
    padding-top:10px;
}
